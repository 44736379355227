import * as actionTypes from "../../../../store/actions";
import produce from "immer";
import {
    NOTIFICATION_PREFERENCES_LOAD,
    NOTIFICATION_PREFERENCES_LOADED,
    NOTIFICATION_PREFERENCES_SAVE_ALL_TRIALS, NOTIFICATION_PREFERENCES_SAVE_GENERAL,
    NOTIFICATION_PREFERENCES_SAVE_SPECIFIC_TRIAL,
    NOTIFICATION_PREFERENCES_SAVED_ALL_TRIALS, NOTIFICATION_PREFERENCES_SAVED_GENERAL,
    NOTIFICATION_PREFERENCES_SAVED_SPECIFIC_TRIAL, NOTIFICATION_PREFERENCES_TOGGLE_GENERAL_NOTIFICATION_TYPE,
    NOTIFICATION_PREFERENCES_TOGGLE_TRIAL_NOTIFICATION_TYPE, NOTIFICATION_PREFERENCES_TOGGLED_GENERAL_NOTIFICATION_TYPE,
    NOTIFICATION_PREFERENCES_TOGGLED_TRIAL_NOTIFICATION_TYPE
} from "../../../../store/actions";
import {NotificationFrequencyEnum} from "../notificationFrequencyEnum";
import {NotificationTypeEnum} from "../notificationTypeEnum";
import {NotificationMethodEnum} from "../notificationMethodEnum";
import {TrialsService, UsersService} from "../../../../api/connect";


const initialState = {
    isLoadingPreferences: true,
    isSavingPreference: false,
    preferences: null
};

const notificationsPreferencesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.NOTIFICATION_PREFERENCES_TOGGLE_GENERAL_NOTIFICATION_TYPE:
            return produce(state, draft => {
                const notificationType = draft.preferences.general.types.find(n => n.id === action.notificationTypeId);
                notificationType.email = !notificationType.email;

                if (notificationType.email && draft.preferences.general.all === NotificationFrequencyEnum.TURN_OFF_ALL_NOTIFICATIONS){
                    draft.preferences.general.all = NotificationFrequencyEnum.SEND_AS_THEY_HAPPEN;
                }

                if (!notificationType.email && draft.preferences.general.all !== NotificationFrequencyEnum.TURN_OFF_ALL_NOTIFICATIONS){
                    draft.preferences.general.all = NotificationFrequencyEnum.TURN_OFF_ALL_NOTIFICATIONS;
                }

                draft.isSavingPreference = true;
            });
        case actionTypes.NOTIFICATION_PREFERENCES_TOGGLED_GENERAL_NOTIFICATION_TYPE:
            return produce(state, draft => {
                draft.isSavingPreference = false;
            });
        case actionTypes.NOTIFICATION_PREFERENCES_TOGGLE_TRIAL_NOTIFICATION_TYPE:
            return produce(state, draft => {
                const trialIndex = draft.preferences.trials.specific.findIndex(t => t.id === action.trialId);
                const trial = draft.preferences.trials.specific[trialIndex];
                const notificationTypeIndex = trial.types.findIndex(n => n.id === action.notificationTypeId);
                const notificationType = trial.types[notificationTypeIndex];
                notificationType.email = !notificationType.email;


                if (notificationType.email && trial.frequency === NotificationFrequencyEnum.TURN_OFF_ALL_NOTIFICATIONS){
                    trial.frequency = NotificationFrequencyEnum.SEND_AS_THEY_HAPPEN;
                }

                if (!notificationType.email && trial.frequency !== NotificationFrequencyEnum.TURN_OFF_ALL_NOTIFICATIONS){
                    trial.frequency = NotificationFrequencyEnum.TURN_OFF_ALL_NOTIFICATIONS;
                }

                draft.isSavingPreference = true;
            });
        case actionTypes.NOTIFICATION_PREFERENCES_TOGGLED_TRIAL_NOTIFICATION_TYPE:
            return produce(state, draft => {
                draft.isSavingPreference = false;
            });
        case actionTypes.NOTIFICATION_PREFERENCES_SAVE_SPECIFIC_TRIAL:
            return produce(state, draft => {
                draft.isSavingPreference = true;

                const trialIndex = draft.preferences.trials.specific.findIndex(t => t.id === action.trialId);
                const trial = draft.preferences.trials.specific[trialIndex];

                trial.types.forEach(t => {
                    t.email = (action.preference === NotificationFrequencyEnum.SEND_AS_THEY_HAPPEN);
                });

                const findChangedTrial = draft.preferences.trials.specific.find(t => t.id === action.trialId);
                if(findChangedTrial){
                    findChangedTrial.frequency = action.preference;
                }

                // check if all trials have the same notification type otherwise make all trials value = "customised"
                const diff = draft.preferences.trials.specific.find(t => t.frequency !== draft.preferences.trials.all);
                if (diff) {
                    draft.preferences.trials.all = NotificationFrequencyEnum.CUSTOMISED;
                }

            });
        case actionTypes.NOTIFICATION_PREFERENCES_SAVED_SPECIFIC_TRIAL:
            return produce(state, draft => {
                draft.isSavingPreference = false;
            });
        case actionTypes.NOTIFICATION_PREFERENCES_SAVE_ALL_TRIALS:
            return produce(state, draft => {
                draft.isSavingPreference = true;
                draft.preferences.trials.all = action.preference;
            });
        case actionTypes.NOTIFICATION_PREFERENCES_SAVED_ALL_TRIALS:
            return produce(state, draft => {
                draft.isSavingPreference = false;
            });
        case actionTypes.NOTIFICATION_PREFERENCES_SAVE_GENERAL:
            return produce(state, draft => {
                draft.isSavingPreference = true;
                draft.preferences.general.all = action.preference;

                const notificationType = draft.preferences.general.types[0];
                if (!notificationType.email && draft.preferences.general.all !== NotificationFrequencyEnum.TURN_OFF_ALL_NOTIFICATIONS){
                    notificationType.email = true;
                }
            });
        case actionTypes.NOTIFICATION_PREFERENCES_SAVED_GENERAL:
            return produce(state, draft => {
                draft.isSavingPreference = false;
            });
        case actionTypes.NOTIFICATION_PREFERENCES_LOAD:
            return produce(state, draft => {
                draft.isLoadingPreferences = true;
            });
        case actionTypes.NOTIFICATION_PREFERENCES_LOADED:
            return produce(state, draft => {


                const trialSpecific = action.trials.map(t => ({
                    id: t.id,
                    name: t.trialname,
                    trialIdentifier: t.trialIdentifier,
                    protocolNumber: t.protocolNumber,
                    frequency: NotificationFrequencyEnum.TURN_OFF_ALL_NOTIFICATIONS,
                    types: [
                        {
                            id: 1,
                            name: 'New referrals',
                            description: 'When a new participant is referred to you',
                            type: NotificationTypeEnum.Site_NewReferral,
                            email: false
                        },
                        {
                          id: 2,
                          name: 'File request',
                          description: 'When a file request is submitted',
                          type: NotificationTypeEnum.Site_RequestFile,
                          email: false
                      }                        
                    ]
                }));

                draft.preferences = {
                    general: {
                        all: NotificationFrequencyEnum.SEND_AS_THEY_HAPPEN,
                        types: [
                            {
                                id: 1,
                                name: 'Added to company',
                                description: 'When a user is added to your company',
                                type: NotificationTypeEnum.All_AddedToCompany,
                                email: true
                            },
                        ]
                    },
                    trials: {
                        all: NotificationFrequencyEnum.SEND_AS_THEY_HAPPEN,
                        specific: trialSpecific
                    }
                }

                if (action.preferences){
                    draft.preferences.general.all = action.preferences.general;
                    draft.preferences.trials.all = action.preferences.trial;

                    action.preferences.generalSpecific.forEach(g => {
                        const emailType = draft.preferences.general.types.find(t => t.type === g.type);
                        if (emailType) {
                            emailType.email = g.enabled;
                        }
                    });

                    action.preferences.trialSpecific.forEach(t => {
                        const trial = draft.preferences.trials.specific.find(x => x.id === t.trialId);
                        if (trial) {
                            trial.frequency = t.frequency;
                            const emailType = trial.types.find(n => n.type === t.type);
                            if (emailType) {
                                emailType.email = t.enabled;
                            }
                        } else {
                            console.warn(`User no longer has access to trial: ${t.trialId}`);
                        }
                    });
                }

                draft.isLoadingPreferences = false;
            });
        default:
            return state;
    }
}

export default notificationsPreferencesReducer;


// Effects
export function getPreferences(userId) {
    return async (dispatch, getState) => {
        dispatch({type: NOTIFICATION_PREFERENCES_LOAD});
        return TrialsService.getApiTrialsGetTrialNames(userId, false)
            .then(trials => {
                UsersService.getApiUsersGetNotificationSettings(userId)
                    .then(preferences => {
                        dispatch({
                            type: NOTIFICATION_PREFERENCES_LOADED,
                            preferences: preferences.results,
                            trials: trials.results
                        })
                    })
                    .catch(error => console.error(error));
            })
            .catch(error => console.error(error));
    }
}

export function saveGeneralPreferences(preference, userId, hasAccessToTrials) {
    return async (dispatch, getState) => {
        dispatch({type: NOTIFICATION_PREFERENCES_SAVE_GENERAL, preference});
        const preferences = getState().notificationsPreferences.preferences;
        const payload = makePayload(preferences, userId, hasAccessToTrials);
        return  UsersService.postApiUsersSaveNotificationSettings(payload)
            .then(result => dispatch({type: NOTIFICATION_PREFERENCES_SAVED_GENERAL, preferences}))
            .catch(error => console.error(error));
    }
}

export function saveAllTrialsPreferences(preference, userId, hasAccessToTrials) {
    return async (dispatch, getState) => {
        dispatch({type: NOTIFICATION_PREFERENCES_SAVE_ALL_TRIALS, preference});
        const preferences = getState().notificationsPreferences.preferences;
        const payload = makePayload(preferences, userId, hasAccessToTrials);
        return  UsersService.postApiUsersSaveNotificationSettings(userId,payload)
            .then(result => dispatch({type: NOTIFICATION_PREFERENCES_SAVED_ALL_TRIALS, preferences}))
            .catch(error => console.error(error));
    }
}

export function toggleGeneralNotificationType(notificationTypeId, userId, hasAccessToTrials) {
    return async (dispatch, getState) => {
        dispatch({type: NOTIFICATION_PREFERENCES_TOGGLE_GENERAL_NOTIFICATION_TYPE, notificationTypeId});
        const preferences = getState().notificationsPreferences.preferences;
        const payload = makePayload(preferences, userId, hasAccessToTrials);
        return  UsersService.postApiUsersSaveNotificationSettings(payload)
            .then(result => dispatch({type: NOTIFICATION_PREFERENCES_TOGGLED_GENERAL_NOTIFICATION_TYPE, preferences}))
            .catch(error => console.error(error));
    }
}

export function toggleTrialNotificationType(notificationTypeId, trialId, userId, hasAccessToTrials) {
    return async (dispatch, getState) => {
        dispatch({type: NOTIFICATION_PREFERENCES_TOGGLE_TRIAL_NOTIFICATION_TYPE, notificationTypeId, trialId});
        const preferences = getState().notificationsPreferences.preferences;
        const payload = makePayload(preferences, userId, hasAccessToTrials);
        return  UsersService.postApiUsersSaveNotificationSettings(payload)
            .then(result => dispatch({type: NOTIFICATION_PREFERENCES_TOGGLED_TRIAL_NOTIFICATION_TYPE, preferences}))
            .catch(error => console.error(error));
    }
}

export function saveSpecificTrialNotificationType(trialId, preference, userId, hasAccessToTrials) {
    return async (dispatch, getState) => {
        dispatch({type: NOTIFICATION_PREFERENCES_SAVE_SPECIFIC_TRIAL, preference, trialId});
        const preferences = getState().notificationsPreferences.preferences;
        const payload = makePayload(preferences, userId, hasAccessToTrials);
        return  UsersService.postApiUsersSaveNotificationSettings(payload)
            .then(result => dispatch({type: NOTIFICATION_PREFERENCES_SAVED_SPECIFIC_TRIAL, preferences}))
            .catch(error => console.error(error));
    }
}

export function cleanNotificationSettings(userId) {
    return async (dispatch, getState) => {
        return  UsersService.deleteApiUsersClearNotificationSettings()
            .then(result => dispatch(getPreferences(userId)))
            .catch(error => console.error(error));
    }
}


function makePayload(preferences, userId, hasAccessToTrials) {

    const trialSpecific = [];
    const generalSpecific = [];

    preferences.general.types.forEach(x => {
        generalSpecific.push({
            type: x.type,
            method: NotificationMethodEnum.EMAIL,
            frequency: preferences.general.all,
            enabled: x.email
        });
    });

    preferences.trials.specific.forEach(t => {
        t.types.forEach(x => {
            trialSpecific.push({
                trialId: t.id,
                type: x.type,
                method: NotificationMethodEnum.EMAIL,
                frequency: t.frequency,
                enabled: x.email
            });
        })
    });

    return {
        userCognitoSub: userId,
        notificationSettings: {
            general: preferences.general.all,
            generalSpecific: generalSpecific,
            trial: (hasAccessToTrials) ? preferences.trials.all : NotificationFrequencyEnum.TURN_OFF_ALL_NOTIFICATIONS,
            trialSpecific: (hasAccessToTrials) ? trialSpecific : []
        }
    }
}