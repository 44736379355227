//import configuration from './config.local.js';
//import configuration from './config.dev2.js';
import configuration from './config.dev.js';
//import configuration from  './config.uat.js';
//import configuration from './config.prod.js';

const config = configuration;

export default config;


